import Layout from "../components/layout";
import Page from "../components/page";
import React from "react";

export default function RedirectPage(props) {
    const path = props.path;
    console.log(props);

    if (path === "/login" || path === "/register" || path.startsWith("/menus")) {
        window.location.href = "https://app.feed-me.app" + props.path;
        return "Przekierowuję..";
    }

    return <Layout>
        <Page>
            <h3>Nie znaleziono strony</h3>
        </Page>
    </Layout>
}